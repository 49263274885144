//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, watch, reactive } from '@vue/composition-api'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { doc, getFirestore, getDoc } from 'firebase/firestore'
import { useResponsiveWidth } from 'assets/screenUtility'
import { ADMIN, AUDITOR, MANAGER } from 'assets/userRoles'
import { provideToast } from 'vue-toastification/composition'
import { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import moment from 'moment'
import 'moment-timezone'

export default defineComponent({
  setup(_, { root }) {
    const { $store, $nuxt } = root
    const auth = getAuth()
    const db = getFirestore()
    const isLoggedIn = ref(false)
    const transparentNavBar = ref(false)
    moment.tz.setDefault('Asia/Singapore')

    provideToast({ position: POSITION.TOP_CENTER })

    // Global event bus
    $nuxt.$on('setTransparentNavBar', (transparent) => {
      transparentNavBar.value = transparent
    })

    onAuthStateChanged(auth, (user) => {
      if (user) {
        getUser(user.uid)
        isLoggedIn.value = true
        $store.commit('setIsLoggedIn', true)
      } else {
        isLoggedIn.value = false
        $store.commit('setIsLoggedIn', false)
        $store.commit('setIsAdmin', false)
        $store.commit('setIsAuditor', false)
        $store.commit('setIsManager', false)
      }
    })

    const { width } = useResponsiveWidth()
    const screenWidth = ref(window.innerWidth)
    $store.commit('setScreenWidth', screenWidth)

    watch(width, (val) => {
      screenWidth.value = val
      $store.commit('setScreenWidth', val)
    })

    watch(
      () => $store.state.currentUserData.roles,
      (roles) => {
        if (roles && roles.length > 0) {
          $store.commit('setIsAdmin', roles.includes(ADMIN))
          $store.commit('setIsManager', roles.includes(MANAGER))
          $store.commit('setIsAuditor', roles.includes(AUDITOR))
        }
      }
    )

    const getUser = async (uid) => {
      const docRef = doc(db, 'users', uid)
      const docSnap = await getDoc(docRef)
      const user = docSnap.data()
      user.uid = uid
      $store.commit('setCurrentUserData', user)
    }

    const signUserOut = () => {
      signOut(auth)
        .then(() => {
          $store.commit('setIsAdmin', false)
          $store.commit('setIsManager', false)
          $store.commit('setIsAuditor', false)
          $store.commit('setCurrentUserData', {})
          // Sign-out successful.
        })
        .catch((error) => {
          console.log(error)
          // An error happened.
        })
    }

    const drawer = ref(null)
    const items = reactive({
      list: [
        // { title: 'Home', icon: 'mdi-home', to: '/' },
        // { title: 'Profile', icon: 'mdi-account', to: '/profile' },
        // { title: 'Reservations', icon: 'mdi-table-chair', to: '/reserve' },
        // { title: 'Privacy Policy', icon: 'mdi-file-document-outline', to: '' },
        // {
        //   title: 'Terms & Conditions',
        //   icon: 'mdi-file-document-outline',
        //   to: '',
        // },
      ],
    })

    return {
      signUserOut,
      isLoggedIn,
      screenWidth,
      drawer,
      items,
      transparentNavBar,
    }
  },
})
