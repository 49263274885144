import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics";
/*
  Change depending on enviroment

  Run if production: firebase use sevendaze-sg

  Run if development: firebase use sevendaze-beer-comp
*/
const isProduction = true;


let firebaseConfig = {
  apiKey: "AIzaSyDKC9JNtmb1xS9Pxz_tW6aypgMd3l96Eyk",
  authDomain: "sevendaze-sg.firebaseapp.com",
  projectId: "sevendaze-sg",
  storageBucket: "sevendaze-sg.appspot.com",
  messagingSenderId: "710701647158",
  appId: "1:710701647158:web:7d0af88012df1267299f97",
  measurementId: "G-XQGGE6N1JP"
};

if (!isProduction) {
  firebaseConfig = {
    apiKey: "AIzaSyAK32R2d3Eq3hULvQ974psm1LPIVloWA-0",
    authDomain: "sevendaze-beer-comp.firebaseapp.com",
    projectId: "sevendaze-beer-comp",
    storageBucket: "sevendaze-beer-comp.appspot.com",
    messagingSenderId: "330440016540",
    appId: "1:330440016540:web:0ab2dcc32dd0c8c4eae359",
    measurementId: "G-CYJGEE6GP4"
  }
}

const app = initializeApp(firebaseConfig)
getAnalytics(app)
