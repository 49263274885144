import moment from 'moment'
export const state = () => ({
    signUpFormData: {},
    currentUserData: {},
    isAdmin: false,
    isManager: false,
    isAuditor: false,
    isLoggedIn: false,
    screenWidth: window.innerWidth,
    todayDate: moment().toString()
})

export const mutations = {
    setTodayDate(state, date) {
        state.todayDate = date
    },
    setSignUpFormData(state, formData) {
        state.signUpFormData = formData
    },
    setCurrentUserData(state, userData) {
        state.currentUserData = userData
    },
    setIsAdmin(state, isAdmin) {
        state.isAdmin = isAdmin
    },
    setIsManager(state, isManager) {
        state.isManager = isManager
    },
    setIsAuditor(state, isAuditor) {
        state.isAuditor = isAuditor
    },
    setIsLoggedIn(state, loggedIn) {
        state.isLoggedIn = loggedIn
    },
    setScreenWidth(state, screenWidth) {
        state.screenWidth = screenWidth
    }
}