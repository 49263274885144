import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _038fb430 = () => interopDefault(import('../pages/claim-logs.vue' /* webpackChunkName: "pages/claim-logs" */))
const _cee584ee = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _8a7c5e86 = () => interopDefault(import('../pages/promo-logs.vue' /* webpackChunkName: "pages/promo-logs" */))
const _42641f9e = () => interopDefault(import('../pages/redemption-logs.vue' /* webpackChunkName: "pages/redemption-logs" */))
const _391ab1ae = () => interopDefault(import('../pages/team-list.vue' /* webpackChunkName: "pages/team-list" */))
const _61f7d63f = () => interopDefault(import('../pages/team-logs.vue' /* webpackChunkName: "pages/team-logs" */))
const _a7527800 = () => interopDefault(import('../pages/user-list.vue' /* webpackChunkName: "pages/user-list" */))
const _6234cb1c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/claim-logs",
    component: _038fb430,
    name: "claim-logs"
  }, {
    path: "/login",
    component: _cee584ee,
    name: "login"
  }, {
    path: "/promo-logs",
    component: _8a7c5e86,
    name: "promo-logs"
  }, {
    path: "/redemption-logs",
    component: _42641f9e,
    name: "redemption-logs"
  }, {
    path: "/team-list",
    component: _391ab1ae,
    name: "team-list"
  }, {
    path: "/team-logs",
    component: _61f7d63f,
    name: "team-logs"
  }, {
    path: "/user-list",
    component: _a7527800,
    name: "user-list"
  }, {
    path: "/",
    component: _6234cb1c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
