export const AdminAddBeer = () => import('../../components/AdminAddBeer.vue' /* webpackChunkName: "components/admin-add-beer" */).then(c => wrapFunctional(c.default || c))
export const AdminAddPrize = () => import('../../components/AdminAddPrize.vue' /* webpackChunkName: "components/admin-add-prize" */).then(c => wrapFunctional(c.default || c))
export const AdminBeerClaim = () => import('../../components/AdminBeerClaim.vue' /* webpackChunkName: "components/admin-beer-claim" */).then(c => wrapFunctional(c.default || c))
export const AdminDialogManageTeam = () => import('../../components/AdminDialogManageTeam.vue' /* webpackChunkName: "components/admin-dialog-manage-team" */).then(c => wrapFunctional(c.default || c))
export const AdminDialogManageUser = () => import('../../components/AdminDialogManageUser.vue' /* webpackChunkName: "components/admin-dialog-manage-user" */).then(c => wrapFunctional(c.default || c))
export const AdminEditAttempt = () => import('../../components/AdminEditAttempt.vue' /* webpackChunkName: "components/admin-edit-attempt" */).then(c => wrapFunctional(c.default || c))
export const AdminPrizeClaim = () => import('../../components/AdminPrizeClaim.vue' /* webpackChunkName: "components/admin-prize-claim" */).then(c => wrapFunctional(c.default || c))
export const AdminTeamAttempts = () => import('../../components/AdminTeamAttempts.vue' /* webpackChunkName: "components/admin-team-attempts" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
